const ACCESS_TOKEN_KEY = 'access_token'

interface ITokenPayload {
    token?: string,
    tokenType?: string,
    expiresAt?: string,
}

export const useToken = () => {
    const getToken = (): any => {
        return window.localStorage.getItem(ACCESS_TOKEN_KEY)
    }

    const setToken = (payload: ITokenPayload): boolean => {
        window.localStorage.setItem(ACCESS_TOKEN_KEY, payload.token + '')
        return true
    }

    const clearToken = (): boolean => {
        window.localStorage.removeItem(ACCESS_TOKEN_KEY)
        return true
    }

    return {
        getToken,
        setToken,
        clearToken,
    }
}