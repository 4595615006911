import {useHttp} from '../http'

export const useUserApi = () => {

    const find = <T>(id: number): Promise<T> => {
        return useHttp().get(`users/${id}`)
    }

    const update = <T>(id: number, payload: any): Promise<T> => {
        return useHttp().put(`users/${id}`, payload)
    }

    const get = <T>(payload: any): Promise<T> => {
        return useHttp().get(`users`, payload)
    }

    return {
        find,
        update,
        get
    }
}
