import Vue from 'vue'
import Vuex from 'vuex'
import * as _ from 'lodash'
import {Notification, RootState} from './store-types'
import {user} from './modules/user'
import {userCollection} from './modules/user-collection'

Vue.use(Vuex)

const state: RootState = {
    notifications: [],
}

const store = new Vuex.Store({
    state,
    mutations: {
        notify(state: any, payload: Notification) {
            state.notifications.push({
                id: state.notifications.length + 1,
                color: payload.color || 'success',
                message: payload.message
            })
        },
        readNotification(state: any, payload: number) {
            const index = _.findIndex(state.notifications, {id: payload})
            if (index !== -1) {
                state.notifications.splice(index, 1)
            }
        },
    },
    modules: {
        user,
        userCollection,
    }
})

export default store

export function useStore() {
    return store
}
