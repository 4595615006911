/**
 * Deep copy the given object.
 *
 * @param  {Object} obj
 * @return {Object}
 */
export function deepCopy(obj: any) {
    if (obj === null || typeof obj !== 'object') {
        return obj
    }

    const copy = Array.isArray(obj) ? <any>[] : {}

    Object.keys(obj).forEach(key => {
        copy[key] = deepCopy(obj[key])
    })

    return copy
}

/**
 * If the given value is not an array, wrap it in one.
 *
 * @param  {any} value
 * @return {Array}
 */
export function arrayWrap(value: any) {
    return Array.isArray(value) ? value : [value]
}

/**
 * @param  {number} min
 * @param  {number} max
 * @return number
 */
export function randomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * @return boolean
 */
export function randomBool() {
    return Math.random() < 0.5
}

/**
 * @param  {string} string
 * @return string
 */
export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}