<template>
  <v-overlay v-if="overlay"
             :value="true"
             :dark="false"
             opacity="1"
             color="#fff"
             absolute
  >
    <v-progress-circular
        indeterminate
        size="48"
        width="3"
        color="secondary"
    ></v-progress-circular>
  </v-overlay>
  <v-flex
      v-else
      class="fill-width fill-height"
  >
    <slot></slot>
  </v-flex>
</template>

<script lang="ts">
import {defineComponent, reactive, toRefs, watch} from '@vue/composition-api'

export default defineComponent({

  name: 'Overlay',

  props: ['value'],

  /**
   * Component constructor.
   * @param props
   * @param context
   */
  setup(props, context) {

    const state = reactive({
      overlay: props.value
    })

    watch(() => props.value, (newValue) => {
      state.overlay = newValue
    })

    return {
      ...toRefs(state)
    }

  }
})
</script>
