import {NavigationGuardNext, Route} from 'vue-router/types/router'
import {useStore} from '@/store'
import {useAuth} from '@/plugins/auth'

const permissionsGuard = (to: Route, from: Route, next: NavigationGuardNext): any => {
    const requiredPermissions = <string[]>[]

    if (to.meta.permissions) {
        const auth = useAuth()
        to.meta.permissions.forEach((permission: string) => {
            if (auth.user().notPermitted(permission)) {
                requiredPermissions.push(permission)
            }
        })
    }

    if (requiredPermissions.length) {
        const store = useStore()
        store.commit('notify', {color: 'error', message: `Permissions required: ${requiredPermissions.join(', ')}`})

        next({name: <string>from.name})
    } else {
        next()
    }
}

export default permissionsGuard
