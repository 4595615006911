<template>
  <div>
    404
  </div>
</template>

<script lang="ts">
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  setup() {
    return {}
  }
})
</script>
