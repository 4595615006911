<template>
  <div class="container">
    <div class="header">
      <img src="/images/zeiterfassung_header.svg" alt="titelbild" class="header-img">
    </div>
    <div class="main">
      <div class="form">
        <h3>Login</h3>
        <input v-model="form.date"
               type="text"
               placeholder="tt.mm.jjjj">
        <input v-model="form.id"
               type="text"
               placeholder="ID Schueler">
        <span class="txt-warning" v-if="form.errors.has('id')">
          {{ form.errors.errors.id[0] }}
        </span>
        <button class="btn-primary touched"
                :disabled="form.busy"
                @click.prevent="submit()">
          Login
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, reactive} from '@vue/composition-api'
import {useStore} from '@/store'
import {useRouter} from '@/composition-api'
import {useAuthService} from '@/core/api/auth'
import {useForm} from '@/core/form'

export default defineComponent({
  /**
   * Component constructor.
   *
   * @param props
   */
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const authService = useAuthService()

    const form = reactive(useForm({
      id: undefined,
      date: undefined
    }))

    const submit = () => {
      form.usingSubmit(authService.login(form.data()))
          .then(() => {
            router.push({name: 'home'})
            store.dispatch('notify', {color: 'success', message: 'Welcome!'})
          })
    }

    return {
      form,
      submit,
    }
  }
})
</script>
