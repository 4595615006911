
<div class="container">
  <div class="header">
    <img src="/images/zeiterfassung_header.svg" alt="titelbild" class="header-img">
    <div class="actions">
        <span class="touched"
              id="btnAttendance"
              @click="showAttendanceForm">
          <i class="fas fa-calendar-alt"></i>
        </span>
      <span class="touched"
            id="btnUpload"
            @click="loadUploadHistory">
        <i class="far fa-file-pdf"></i>
      </span>
      <span class="touched" @click="showAllEntries">
        <i class="fas fa-binoculars touched"></i>
      </span>
      <span v-if="enabledWorkingHours" class="touched" @click="showWorkingHoursForm">
        <i class="far fa-clock"></i>
      </span>
    </div>
    <div class="logout touched" @click="logout">
      <i class="fas fa-sign-out-alt"></i>
    </div>
    <span class="title">Hallo {{ $auth.user().fullName }}</span>
  </div>
  <div class="main">
    <div v-if="currentTab === 1">
      <div class="form">
        <h3>Praktikumsanwesenheit</h3>
        <form>
          <input v-model="attendanceForm.date" type="date"/>
          <label>
            <input v-model="attendanceForm.presence"
                   type="radio"
                   value="1">
            Anwesend
          </label>
          <label v-if="isFFM">
            <input v-model="attendanceForm.presence"
                   type="radio"
                   value="4">
            Urlaub
          </label>
          <label>
            <input v-model="attendanceForm.presence"
                   type="radio"
                   value="2">
            Krank
          </label>
          <label>
            <input v-model="attendanceForm.presence"
                   type="radio"
                   value="3">
            Abwesend
          </label>
          <textarea v-model="attendanceForm.reason"
                    rows="3"
                    placeholder="Hier müssen Sie ergänzende Informationen zum Status Abwesend eintragen.">
          </textarea>
          <input :disabled="busy"
                 class="btn-primary touched"
                 type="submit"
                 @click.prevent="submitAttendanceForm"/>
        </form>
      </div>
    </div>
    <div v-if="currentTab === 2">
      <div class="form">
        <h3>Upload</h3>
        <form method="POST">
          <input @change="onFileChange"
                 type="file"
                 accept=".pdf">
          <input :disabled="busy"
                 class="btn-primary touched"
                 type="submit"
                 value="Upload PDF"
                 @click.prevent="submitUploadForm">
        </form>
      </div>
      <div class="form">
        <h3>Upload History</h3>
        <ul class="timeline">
          <li class="timeline-item" v-for="file in uploadHistory">
            <span>{{ file.org_FileName }}</span>
            <span class="subline">{{
                (new Date(file.uploadDate).toLocaleDateString())
              }} {{ (new Date(file.uploadDate).toLocaleTimeString()) }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="currentTab === 3">
      <div class="form">
        <h3>Eintragungen</h3>
        <i v-if="loadingEntries" class="fas fa-spinner fa-spin"></i>
        <ul v-else-if="entries.length">
          <li v-for="entry in entries">
            <div style="font-size: 1rem; width: 50%; display: flex; justify-content: space-between">
              <span style="font-size: 1rem">
                {{ entry.weekday }}
              </span>
              <span style="text-align: right">
                {{ entry.date }}
              </span>
            </div>
            <span style="font-size: 0.9rem; color: #a4a4a4; align-self: center">
              {{ entry.workingHours.replace("Stunden", "h") }}
            </span>
            <div style="display: flex; justify-content: center; width:10%">
              <i
                  :class="entryTypeToClass(entry.type)"
                  style="font-size: unset"
                  @click="showAbsentInfo(entry)"
                  v-if="!entry.isMissing"/>

              <i
                  class="far fa-edit touched"
                  @click="touchOpenDate(entry.date)"
                  v-if="entry.isMissing && entry.isEditable && !entry.type"/>
              <i
                  class="far fa-clock touched"
                  @click="touchOpenHours(entry.date)"
                  v-else-if="entry.isMissing && entry.isEditable && !entry.workingHours"/>
              <i
                  class="fas fa-question txt-warning"
                  @click="showQuestionMarkInfo()"
                  v-else-if="entry.isMissing && !entry.isEditable"/>
            </div>
          </li>
        </ul>
        <span v-else>Keine Eintragungen vorhanden.</span>
      </div>
    </div>
    <div v-if="currentTab === 4">
      <div class="form">
        <h3>Arbeitszeiten</h3>
        <form>
          <input v-model="workingHoursForm.date"
                 type="date"
                 @change="loadWorkingHours"/>
          <i v-if="loadingWorkingTimes" class="fas fa-spinner fa-spin"></i>
          <div v-else>
            <div class="input-time-group">
              <span>Stunden</span>
              <input v-model="workingHoursForm.hours" type="number" :disabled="lockedWorkingHoursForm"/>
            </div>
            <div class="input-time-group">
              <span>Minuten</span>
              <input v-model="workingHoursForm.minutes" type="number" :disabled="lockedWorkingHoursForm"/>
            </div>
          </div>
          <span class="txt-warning" v-if="Object.keys(workingHoursForm.errors.errors).length">
            {{ Object.values(workingHoursForm.errors.errors)[0][0] }}
          </span>
          <input
              class="btn-primary touched"
              type="submit"
              value="Speichern"
              :disabled="busy || lockedWorkingHoursForm"
              @click.prevent="submitWorkingHoursForm">
        </form>
      </div>
    </div>
  </div>
</div>
