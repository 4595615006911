<template>
  <div></div>
</template>

<script lang="ts">
import {defineComponent} from '@vue/composition-api'
import {useRouter} from '@/composition-api'
import {useAuth} from '@/plugins/auth'

export default defineComponent({
  /**
   * Component constructor.
   * @param props
   */
  setup(props) {
    const auth = useAuth()

    auth.logout()

    const router = useRouter()

    router.push({name: 'login'})
  }
})
</script>
