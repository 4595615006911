
<div class="container">
  <div class="header">
    <img src="/images/zeiterfassung_header.svg" alt="titelbild" class="header-img">
  </div>
  <div class="main">
    <div class="form">
      <h3>Login</h3>
      <input v-model="form.date"
             type="text"
             placeholder="tt.mm.jjjj">
      <input v-model="form.id"
             type="text"
             placeholder="ID Schueler">
      <span class="txt-warning" v-if="form.errors.has('id')">
        {{ form.errors.errors.id[0] }}
      </span>
      <button class="btn-primary touched"
              :disabled="form.busy"
              @click.prevent="submit()">
        Login
      </button>
    </div>
  </div>
</div>
