export interface UserPayload {
    IDSchueler: number
    Nachname: string
    Vorname: string
    permissions?: []
}

export class User {

    id: number
    name: string
    lastName: string
    permissions: string[]

    constructor(data: UserPayload) {
        this.id = data.IDSchueler
        this.name = data.Nachname
        this.lastName = data.Vorname
        this.permissions = data.permissions || []
    }

    fill(data: UserPayload): this {
        this.id = data.IDSchueler
        this.name = data.Nachname
        this.lastName = data.Vorname
        this.permissions = data.permissions || []
        return this
    }

    public get fullName(): string {
        return this.name + ' ' + this.lastName
    }

    permitted(permission: string): boolean {
        for (let i = 0; i < this.permissions.length; i++) {
            let item = <string>this.permissions[i]
            if (permission === item) {
                return true
            }
            const permissionArgs = item.split('.')
            const high = permissionArgs.slice(-1).pop()
            if (high === '*') {
                item = permissionArgs.slice(0, -1).join('.')
                if (permission.indexOf(item) === 0) {
                    return true
                }
            }
        }
        return false
    }

    notPermitted(permission: string): boolean {
        return !this.permitted(permission)
    }

    payload(): UserPayload {
        return {
            IDSchueler: this.id,
            Nachname: this.name,
            Vorname: this.lastName,
        }
    }
}
