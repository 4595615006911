import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import Maska from 'maska'
import {useAuth} from '@/plugins/auth'
import Overlay from '@/components/Overlay.vue'
import App from '@/App.vue'

Vue.use(VueCompositionAPI)
Vue.use(Maska)

Vue.component('overlay', <any>Overlay)

const guard = useAuth()

guard
    .auth()
    .finally(() => {
        const app = new Vue({
            store,
            router,
            /*
            vuetify,
            */
            render: (h) => h(App),
        })

        const vm = app.$mount('#app')
    })

guard.watchToken()
