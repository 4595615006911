<template>
  <div class="container">
    <div class="header">
      <img src="/images/zeiterfassung_header.svg" alt="titelbild" class="header-img">
      <div class="actions">
          <span class="touched"
                id="btnAttendance"
                @click="showAttendanceForm">
            <i class="fas fa-calendar-alt"></i>
          </span>
        <span class="touched"
              id="btnUpload"
              @click="loadUploadHistory">
          <i class="far fa-file-pdf"></i>
        </span>
        <span class="touched" @click="showAllEntries">
          <i class="fas fa-binoculars touched"></i>
        </span>
        <span v-if="enabledWorkingHours" class="touched" @click="showWorkingHoursForm">
          <i class="far fa-clock"></i>
        </span>
      </div>
      <div class="logout touched" @click="logout">
        <i class="fas fa-sign-out-alt"></i>
      </div>
      <span class="title">Hallo {{ $auth.user().fullName }}</span>
    </div>
    <div class="main">
      <div v-if="currentTab === 1">
        <div class="form">
          <h3>Praktikumsanwesenheit</h3>
          <form>
            <input v-model="attendanceForm.date" type="date"/>
            <label>
              <input v-model="attendanceForm.presence"
                     type="radio"
                     value="1">
              Anwesend
            </label>
            <label v-if="isFFM">
              <input v-model="attendanceForm.presence"
                     type="radio"
                     value="4">
              Urlaub
            </label>
            <label>
              <input v-model="attendanceForm.presence"
                     type="radio"
                     value="2">
              Krank
            </label>
            <label>
              <input v-model="attendanceForm.presence"
                     type="radio"
                     value="3">
              Abwesend
            </label>
            <textarea v-model="attendanceForm.reason"
                      rows="3"
                      placeholder="Hier müssen Sie ergänzende Informationen zum Status Abwesend eintragen.">
            </textarea>
            <input :disabled="busy"
                   class="btn-primary touched"
                   type="submit"
                   @click.prevent="submitAttendanceForm"/>
          </form>
        </div>
      </div>
      <div v-if="currentTab === 2">
        <div class="form">
          <h3>Upload</h3>
          <form method="POST">
            <input @change="onFileChange"
                   type="file"
                   accept=".pdf">
            <input :disabled="busy"
                   class="btn-primary touched"
                   type="submit"
                   value="Upload PDF"
                   @click.prevent="submitUploadForm">
          </form>
        </div>
        <div class="form">
          <h3>Upload History</h3>
          <ul class="timeline">
            <li class="timeline-item" v-for="file in uploadHistory">
              <span>{{ file.org_FileName }}</span>
              <span class="subline">{{
                  (new Date(file.uploadDate).toLocaleDateString())
                }} {{ (new Date(file.uploadDate).toLocaleTimeString()) }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="currentTab === 3">
        <div class="form">
          <h3>Eintragungen</h3>
          <i v-if="loadingEntries" class="fas fa-spinner fa-spin"></i>
          <ul v-else-if="entries.length">
            <li v-for="entry in entries">
              <div style="font-size: 1rem; width: 50%; display: flex; justify-content: space-between">
                <span style="font-size: 1rem">
                  {{ entry.weekday }}
                </span>
                <span style="text-align: right">
                  {{ entry.date }}
                </span>
              </div>
              <span style="font-size: 0.9rem; color: #a4a4a4; align-self: center">
                {{ entry.workingHours.replace("Stunden", "h") }}
              </span>
              <div style="display: flex; justify-content: center; width:10%">
                <i
                    :class="entryTypeToClass(entry.type)"
                    style="font-size: unset"
                    @click="showAbsentInfo(entry)"
                    v-if="!entry.isMissing"/>

                <i
                    class="far fa-edit touched"
                    @click="touchOpenDate(entry.date)"
                    v-if="entry.isMissing && entry.isEditable && !entry.type"/>
                <i
                    class="far fa-clock touched"
                    @click="touchOpenHours(entry.date)"
                    v-else-if="entry.isMissing && entry.isEditable && !entry.workingHours"/>
                <i
                    class="fas fa-question txt-warning"
                    @click="showQuestionMarkInfo()"
                    v-else-if="entry.isMissing && !entry.isEditable"/>
              </div>
            </li>
          </ul>
          <span v-else>Keine Eintragungen vorhanden.</span>
        </div>
      </div>
      <div v-if="currentTab === 4">
        <div class="form">
          <h3>Arbeitszeiten</h3>
          <form>
            <input v-model="workingHoursForm.date"
                   type="date"
                   @change="loadWorkingHours"/>
            <i v-if="loadingWorkingTimes" class="fas fa-spinner fa-spin"></i>
            <div v-else>
              <div class="input-time-group">
                <span>Stunden</span>
                <input v-model="workingHoursForm.hours" type="number" :disabled="lockedWorkingHoursForm"/>
              </div>
              <div class="input-time-group">
                <span>Minuten</span>
                <input v-model="workingHoursForm.minutes" type="number" :disabled="lockedWorkingHoursForm"/>
              </div>
            </div>
            <span class="txt-warning" v-if="Object.keys(workingHoursForm.errors.errors).length">
              {{ Object.values(workingHoursForm.errors.errors)[0][0] }}
            </span>
            <input
                class="btn-primary touched"
                type="submit"
                value="Speichern"
                :disabled="busy || lockedWorkingHoursForm"
                @click.prevent="submitWorkingHoursForm">
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, reactive, ref, toRefs} from '@vue/composition-api'
import {useRouter} from '@/composition-api'
import {useForm} from '@/core/form'
import {useHttp} from '@/core/http'
import swal from "sweetalert";
import moment from 'moment';

type Absent = {
  weekday: string,
  date: string,
  type: string,
  reason: string,
  isMissing: boolean,
  isEditable: boolean,
  workingHours: string
}

export default defineComponent({
  /**
   * Component constructor.
   *
   * @param props
   */
  setup(props) {
    const router = useRouter()
    const http = useHttp()

    const state = reactive({
      currentTab: 1,
      dateTitle: '',
      date: '',
      file: '',
      loadingEntries: false,
      entries: <Absent[]>[],
      busy: false,
      loadingWorkingTimes: false,
      uploadHistory: [],
      lockedWorkingHoursForm: false,
      enabledWorkingHours: false,
      isFFM: false
    })

    const logout = () => {
      router.push({name: 'logout'})
    }

    const syncDate = () => {
      const date = new Date()
      const arrayTage = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']

      let day = ('0' + date.getDate()).slice(-2)
      let month = ('0' + (date.getMonth() + 1)).slice(-2)

      state.dateTitle = `${arrayTage[date.getDay()]}, ${day}.${month}.${date.getFullYear()}`
      state.date = date.getFullYear() + '-' + (month) + '-' + (day)
    }
    syncDate()

    http.get('/feature/working-hours').then((data: any) => state.enabledWorkingHours = data)

    http.get('params?param=appLocation').then((data: any) => {
      state.isFFM = data.data === 'FFM'
    })

    const attendanceForm = reactive(useForm({
      date: state.date,
      presence: '1',
      reason: ''
    }))
    const uploadForm = reactive(useForm())

    const showAttendanceForm = () => {
      state.currentTab = 1
      attendanceForm.date = workingHoursForm.date
    }
    const submitAttendanceForm = () => {
      state.busy = true
      if (attendanceForm.reason === '' && attendanceForm.presence === '3') {
        swal('Warnung', 'Bitte einen Grund angeben!', 'warning')
        state.busy = false
      } else {
        if (attendanceForm.presence === '4') {
          attendanceForm.reason = 'Urlaub'
        }

        attendanceForm.post('/workshop/absenteeism')
            .then((data: any) => {
              let msg = data.result.message
              if (msg.includes("erfolgreich")) {
                swal('Erfolgreich', msg, 'success')
              } else {
                swal('Fehler', msg, 'error')
              }
            })
            .finally(() => {
              state.busy = false
            })
      }
    }
    const submitUploadForm = () => {
      if (state.file) {
        let data = new FormData()
        data.append('files[]', state.file)
        state.busy = true
        uploadForm.post('/workshop/upload', {data: data})
            .then(() => {
              swal('Erfolgreich', 'Datei hochgeladen.', 'success')
            })
            .finally(() => {
              state.busy = false
            })
            .catch((response) => {
              if (response.statusText == 'Payload Too Large') {
                swal('Fehler', 'Datei zu groß', 'error')
              } else {
                swal('Fehler', response.statusText, 'error')
              }

            })
      } else {
        swal('Fehler', 'Keine Datei ausgewählt!', 'error')
      }
    }
    const onFileChange = (event: any) => {
      let files = event.target.files
      if (files.length) {
        state.file = files[0]
      }
    }

    const dates = ref(<HTMLElement>{})
    const showAllEntries = () => {
      state.currentTab = 3
      state.loadingEntries = true
      http.get('/workshop/entries').then((data: any) => {
        state.entries = data.data
        state.loadingEntries = false
      })
          .catch(() => {
            swal('Fehler!', 'Die Eintragungen konnten nicht geladen werden.', 'error')
            state.loadingEntries = false
          })
    }

    const entryTypeToClass = (type: string) => {
      switch (type) {
        case "Anwesend":
          return "fas fa-check txt-success touched"
        case "Nachgearbeitet":
          return "fas fa-check-double txt-success-dark touched"
        case "Entschuldigt":
          return "fas fa-times txt-success touched"
        case "Unentschuldigt":
          return "fas fa-times txt-warning touched"
      }
    }

    const showAbsentInfo = (absent: Absent) => {
      let txt
      switch (absent.type) {
        case "Anwesend":
          txt = "Die Anwesenheit wurde erfolgreich eingetragen."
          break;
        case "Nachgearbeitet":
          txt = "Dieser Tag wurde nachgearbeitet."
          break;
        case "Entschuldigt":
          txt = "Dieser Fehltag wurde entschuldigt."
          break;
        case "Unentschuldigt":
          txt = "Dieser Fehltag ist unentschuldigt."
          break;
        default:
          txt = "Keine Info"
          break;
      }

      if (absent.reason) {
        txt += "\n\nGrund der Abwesenheit:\n" + absent.reason
      }

      swal('', txt, "info")
    }

    const showQuestionMarkInfo = () => {
      const txt = "Die Anwesenheit wurde nicht eingetragen. Ein Nachtragen ist nicht mehr möglich."
      swal('', txt, 'info')
    }

    const touchOpenDate = (date: string) => {
      state.currentTab = 1
      attendanceForm.date = moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD')
    }

    const touchOpenHours = (date: string) => {
      state.currentTab = 4
      workingHoursForm.date = moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD')
      loadWorkingHours()
    }

    const loadUploadHistory = () => {
      state.currentTab = 2
      http.get('/workshop/upload-history').then((data: any) => {
        state.uploadHistory = data.data
      })
    }

    const workingHoursForm = reactive(useForm({
      date: state.date,
      hours: '',
      minutes: ''
    }))

    const showWorkingHoursForm = () => {
      state.currentTab = 4
      workingHoursForm.date = attendanceForm.date
      loadWorkingHours()
    }

    const loadWorkingHours = () => {
      workingHoursForm.hours = ''
      workingHoursForm.minutes = ''
      state.lockedWorkingHoursForm = false
      state.loadingWorkingTimes = true
      workingHoursForm.get('/internship-working-hours/' + workingHoursForm.date)
          .then((workingTime: any) => {
            workingHoursForm.hours = workingTime.hours
            workingHoursForm.minutes = workingTime.minutes
          })
          .catch((response) => {
            if (response.data.status === "locked") {
              state.lockedWorkingHoursForm = true
            }
          })
          .finally(() => state.loadingWorkingTimes = false)
    }

    const submitWorkingHoursForm = () => {
      state.busy = true
      workingHoursForm.put('/internship-working-hours', {data: workingHoursForm.data()})
          .then(() => {
            swal('Erfolgreich', 'Arbeitszeit wurde gespeichert.', 'success')
          })
          .finally(() => {
            state.busy = false
          })
    }

    return {
      attendanceForm,
      uploadForm,
      logout,
      showAttendanceForm,
      submitAttendanceForm,
      submitUploadForm,
      onFileChange,
      dates,
      showAllEntries,
      touchOpenDate,
      touchOpenHours,
      loadUploadHistory,
      workingHoursForm,
      showWorkingHoursForm,
      loadWorkingHours,
      submitWorkingHoursForm,
      entryTypeToClass,
      showAbsentInfo,
      showQuestionMarkInfo,
      ...toRefs(state)
    }
  }
})
</script>
