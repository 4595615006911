import {NavigationGuardNext, Route} from 'vue-router/types/router'
import {useAuth} from '@/plugins/auth'

const authGuard = (to: Route, from: Route, next: NavigationGuardNext): any => {
    const auth = useAuth()

    if (!auth.signed()) {
        next({name: 'login'})
    } else {
        next()
    }
}

export default authGuard
