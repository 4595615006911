
<v-overlay v-if="overlay"
           :value="true"
           :dark="false"
           opacity="1"
           color="#fff"
           absolute
>
  <v-progress-circular
      indeterminate
      size="48"
      width="3"
      color="secondary"
  ></v-progress-circular>
</v-overlay>
<v-flex
    v-else
    class="fill-width fill-height"
>
  <slot></slot>
</v-flex>
