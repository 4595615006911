import Vue from 'vue'
import Router from 'vue-router'
import multiguard from 'vue-router-multiguard'
import authGuard from '@/core/middleware/auth-guard'
import authSignedGuard from '@/core/middleware/auth-signed-guard'
import permissionsGuard from '@/core/middleware/permissions-guard'
import Login from '@/pages/login.vue'
import Logout from '@/pages/logout.vue'
import Home from '@/pages/index.vue'
import PageNotFound from '@/pages/[...all].vue'

Vue.use(Router)

const routes = [
    {
        name: 'home',
        path: '/',
        component: Home,
        beforeEnter: multiguard([authGuard])
    },
    {
        name: 'login',
        path: '/login',
        component: Login,
        beforeEnter: multiguard([authSignedGuard]),
        meta: {
            title: 'Log In'
        }
    },
    {
        name: 'logout',
        path: '/logout',
        component: Logout,
        beforeEnter: multiguard([authGuard]),
    },
    {
        path: '*',
        component: PageNotFound,
        meta: {
            title: '404 Not Found'
        }
    }
]

const createRouter = () => new Router({
    mode: 'history',
    routes
})

const router = createRouter()

router.beforeEach(multiguard([permissionsGuard]))

export default router
