<template>
  <transition name="page" mode="out-in">
    <component :is="layout"></component>
  </transition>
</template>

<style lang="sass">
@import './web/zeiterfassung'
</style>

<script lang="ts">
import {defineComponent, provide} from "@vue/composition-api";
import Default from "./layouts/default.vue";

const defaultLayout = "default";

export default defineComponent({
  name: "App",

  components: {
    Default
  },

  data() {
    return {
      layout: this.$route.meta?.layout || defaultLayout,
    }
  },

  watch: {
    $route: {
      handler(route) {
        this.layout = route.meta?.layout || defaultLayout;
      }
    }
  },

  setup(props, context) {
    provide("router", context.root.$router);
  },
});

</script>
